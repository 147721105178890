//@ts-nocheck
import _ from "lodash";
import ReactDOMServer from "react-dom/server";
import { useEffect, useMemo, useRef, useState, useCallback } from "react";
import L from "leaflet";
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import { GoogleMap, useJsApiLoader } from "@react-google-maps/api";
import classNames from "classnames";
import dayjs from "dayjs";
import {
  Button,
  Spin,
  Row,
  Col,
  Card,
  Skeleton,
  message,
  Empty,
  Flex,
  Tabs,
  Modal,
} from "antd";
import Slider, { Settings } from "react-slick";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  LoadingOutlined,
  InfoCircleOutlined,
  RightOutlined,
  LeftOutlined,
} from "@ant-design/icons";

import useCommonStore from "stores/useCommon";
import SuggestionRenovation from "components/dashboard/suggestion-renovation";
import GraphePrice from "components/dashboard/graphe-price";
import DistributionChart from "components/dashboard/distribution-chart";
import ResultatTab from "components/dashboard/resultat-tab";

import QUERY_KEYS from "services/api/queryKeys";
import API from "services/api";
import { MAP_COLOR_DPE } from "constants/index";
import PAGES, { getDashboardURL } from "routes/constants";
import { ReactComponent as IconLocation } from "assets/images/location.svg";
import { ReactComponent as IconHouse } from "assets/images/house.svg";
import { ReactComponent as IconRuler } from "assets/images/ruler.svg";
// import { ReactComponent as IconDoor } from "assets/images/door.svg";
import { ReactComponent as IconCalendar } from "assets/images/calendar-2.svg";
import { ReactComponent as IconStair } from "assets/images/stair.svg";
import { ReactComponent as IconEnergy } from "assets/images/energy.svg";
import { ReactComponent as IconTemperature } from "assets/images/temperature.svg";
import { ReactComponent as IconWater } from "assets/images/water.svg";
import { ReactComponent as IconWall } from "assets/images/wall.svg";
import { ReactComponent as IconWind } from "assets/images/wind.svg";
import { ReactComponent as IconIce } from "assets/images/ice.svg";
import { ReactComponent as IconCog } from "assets/images/cog.svg";
import { ReactComponent as IconHash } from "assets/images/hash.svg";
import { ReactComponent as IconFinance } from "assets/images/finance.svg";
import { ReactComponent as IconMondpe } from "assets/images/mondpe.svg";
import { ReactComponent as IconReliability } from "assets/images/reliability.svg";
import { ReactComponent as IconAttachment } from "assets/images/attachment.svg";
import { ReactComponent as IconOldCalendar } from "assets/images/calendar-3.svg";
import { ReactComponent as IconHelp } from "assets/images/help-circle.svg";
import { ReactComponent as IconRoof } from "assets/images/renovation/house.svg";
import { ReactComponent as IconWallNew } from "assets/images/renovation/wall.svg";
import { getUserToken } from "services/storages/userStorage";
import { cognitoUserPool } from "config";

import "styles/home.scss";

const MAP_ICON = {
  wall: IconWallNew,
  roof: IconRoof,
};

function Dashboard() {
  const navigate = useNavigate();
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyCGcf514ApTEBKV3u0HyCiLKJmVi_Fimw0",
  });
  const [messageApi, contextHolder] = message.useMessage();
  const { setIsShowInsight } = useCommonStore();

  const mapRef = useRef<any>();

  const distributionRef = useRef<any>(null);
  const dotFiabiliteProgressRef = useRef<any>();
  const sliderRef = useRef(null);
  const reliabilityRef = useRef("");

  const { id } = useParams();

  const [dataHomeUploaded, setDataHomeUploaded] = useState(null);
  const [searchDpesMap, setSearchDpesMap] = useState(null);
  const [resultAutocomplete, setResultAutocomplete] = useState<any[]>([]);
  const [resultAutocompleteTitle, setResultAutocompleteTitle] = useState("");
  const [selectedLabelId, setSelectedLabelId] = useState("");
  const [svgFiabilite, setSvgFiabilite] = useState("");

  const [tab, setTab] = useState("1");
  const [tabMap, setTabMap] = useState("1");
  const [isShowModalMapInfo, setIsShowModalMapInfo] = useState(false);
  const [dataModalFiabilite, setDataModalFiabilite] = useState(null);
  const [isShowModalFiabilite, setIsShowModalFiabilite] = useState(false);
  const [, setMap] = useState(null);

  const { data: dataFiabilite, isFetching: isFetchingFiabilite } = useQuery({
    queryKey: [QUERY_KEYS.GET_DATA_FIABILITE, id],
    queryFn: () => API.mock.getDataFiabilite(id),
    retry: 0,
  });

  const { data: dataSuggestion, isFetching: isFetchingSuggestion } = useQuery({
    queryKey: [QUERY_KEYS.GET_SUGGESTION_RENOVATION, id],
    queryFn: () => API.mock.getSuggestionRenovation(String(id)),
    retry: 0,
  });

  const {
    mutateAsync: mutateUploaded,
    isPending: isPendingUploaded,
    isSuccess,
    isError,
  } = useMutation({
    mutationFn: (params: any) => API.mock.getHomeUploadData(params),
  });

  const { mutateAsync: mutateChatInit, isPending: isPendingChatInit } =
    useMutation({
      mutationFn: (params: any) => API.mock.chatInit(params),
      retry: 0,
    });

  const { mutateAsync: mutateSearchDpesMap, isPending: isPendingSearchMap } =
    useMutation({
      mutationFn: (params: any) => API.mock.searchDpesMap(params),
      retry: 0,
    });

  const { mutateAsync: mutateSVGFiabilite, isPending: isPendingSVGFiabilite } =
    useMutation({
      mutationFn: (params: any) => API.mock.getSVGFiabilite(params),
    });

  const { data: dataGraphePrice } = useQuery({
    queryKey: [QUERY_KEYS.GET_GRAPHE_PRICE, id],
    queryFn: () => API.mock.getGraphePrice(id),
  });

  const { data: geoMap } = useQuery({
    queryKey: [QUERY_KEYS.GET_GEO_MAP, id],
    queryFn: API.mock.getGEOMap,
  });

  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const loadData = async () => {
    try {
      const result = await mutateUploaded({
        id,
      });

      setDataHomeUploaded(result);

      const [resultSearchDpes, resultSvgFiabilite] = await Promise.all([
        mutateSearchDpesMap({
          ref_ademe: id,
          geometry: {
            coordinates: [
              _.get(result, "geo_data.lng"),
              _.get(result, "geo_data.lat"),
            ],
          },
        }),
        mutateSVGFiabilite({
          ref_ademe: id,
        }),
      ]);
      setSvgFiabilite(resultSvgFiabilite);

      setResultAutocomplete(resultSearchDpes?.resp_addresses || []);
      setSearchDpesMap(
        _.uniqBy(
          resultSearchDpes?.surrounding_addresses.map((item: any) => ({
            label: _.get(item, "label.1"),
            lat: _.get(item, "gps.1.0"),
            lng: _.get(item, "gps.1.1"),
            ademe_id: _.get(item, "ademe_id.1"),
            label_range: _.get(item, "label_range.1"),
            label_z: _.get(item, "label_z.1"),
          })),
          (item) => `${item.lat}-${item.lng}`
        )
      );
      setResultAutocompleteTitle(resultSearchDpes?.resp_addresses_title || "");
    } catch (e: any) {
      messageApi.open({
        type: "error",
        content: "Something went wrong",
        duration: 2,
      });
    }
  };

  useEffect(() => {
    drawD3();

    window.addEventListener("resize", drawD3);

    return () => {
      window.removeEventListener("resize", drawD3);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataFiabilite, isFetchingFiabilite, isPendingUploaded]);

  const drawD3 = () => {
    if (distributionRef.current) {
      distributionRef.current.drawD3 && distributionRef.current.drawD3();
    }
  };

  // const handleToEditPage = () => {
  //   if (dataHomeUploaded) {
  //     navigate({
  //       pathname: PAGES.edit.replace(":id", (dataHomeUploaded as any).id),
  //       // search: createSearchParams({
  //       //   mode: "edit",
  //       // }).toString(),
  //     });
  //   }
  // };

  const handleSetDataModalFiabilite = (data, key) => {
    reliabilityRef.current = key;
    setDataModalFiabilite(data);
    setIsShowModalFiabilite(true);
  };

  const handleClickLabel = async (gpsItem) => {
    const center = mapRef.current?.getCenter() || {};
    const resultSearchDpes = await mutateSearchDpesMap({
      ref_ademe: gpsItem.ademe_id,
      geometry: {
        coordinates: [center.lng, center.lat],
      },
      zoom: mapRef.current?.getZoom() || 18,
    });

    setSelectedLabelId(gpsItem.ademe_id);
    setResultAutocomplete(resultSearchDpes.resp_addresses);
    setResultAutocompleteTitle(resultSearchDpes.resp_addresses_title);
    setSearchDpesMap(
      _.uniqBy(
        resultSearchDpes.surrounding_addresses.map((item: any) => ({
          label: _.get(item, "label.1"),
          lat: _.get(item, "gps.1.0"),
          lng: _.get(item, "gps.1.1"),
          ademe_id: _.get(item, "ademe_id.1"),
          label_range: _.get(item, "label_range.1"),
          label_z: _.get(item, "label_z.1"),
        })),
        (item) => `${item.lat}-${item.lng}`
      )
    );

    sliderRef.current?.slickGoTo(0);
  };

  const handleOkFiabilite = async () => {
    if (reliabilityRef.current) {
      try {
        await mutateChatInit({
          ref_ademe: id,
          from: "controls",
          from_details: {
            control_type: reliabilityRef.current,
          },
        });
        setIsShowModalFiabilite(false);
        handleToInsight();
      } catch (e) {
        console.log("error", e);
      }
    }
  };

  const handleToInsight = () => {
    setIsShowInsight(true);
  };

  // const pdfFileName = useMemo(() => {
  //   if (dataHomeUploaded) {
  //     const arrString = (dataHomeUploaded as any).pdf?.split("/");
  //     return arrString[arrString.length - 1];
  //   }
  //   return "";
  // }, [dataHomeUploaded]);

  const centerPoint = useMemo(() => {
    const firstValue = _.get(geoMap, "features.0.geometry.coordinates");
    if (!_.isEmpty(firstValue)) {
      return { lat: firstValue[0], lng: firstValue[1] };
    }
    return {};
  }, [geoMap]);

  const polygonPoint = useMemo(() => {
    const arrayValue = _.get(geoMap, "features.2.geometry.coordinates");
    if (!_.isEmpty(arrayValue)) {
      return _.get(arrayValue, "0", []).map((item: any) => {
        return {
          lat: item[0],
          lng: item[1],
        };
      });
    }
    return {};
  }, [geoMap]);

  const onLoad = useCallback(
    function callback(map: any) {
      // This is just an example of getting and using the map instance!!! don't just blindly copy!
      // if (!_.isNil(centerPoint.lat) && !_.isNil(centerPoint.lng)) {
      //   const bounds = new window.google.maps.LatLngBounds(centerPoint as any);
      //   map.fitBounds(bounds);

      //   setMap(map);
      // }

      // const triangleCoords = [
      //   { lat: 25.774, lng: -80.19 },
      //   { lat: 18.466, lng: -66.118 },
      //   { lat: 32.321, lng: -64.757 },
      //   { lat: 25.774, lng: -80.19 },
      // ];

      const bermudaTriangle = new google.maps.Polygon({
        paths: polygonPoint,
        strokeColor: "#FF0000",
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: "#FF0000",
        fillOpacity: 0.35,
      });

      bermudaTriangle.setMap(map);

      // map.data.add({
      //   geometry: new google.maps.Data.Polygon([polygonPoint]),
      // });
    },
    [polygonPoint]
  );

  const onUnmount = useCallback(function callback(map: any) {
    setMap(null);
  }, []);

  const tabItems: TabsProps["items"] = useMemo(() => {
    const renderTabDuBien = () => {
      const TAB_DU_BIEN1 = [
        {
          icon: IconRuler,
          label: "",
          value: _.get(dataHomeUploaded, "project_details.surface.1", ""),
        },
        {
          icon: IconHouse,
          label: `${_.get(
            dataHomeUploaded,
            "project_details.housing_type.0",
            ""
          )} `,
          value: _.get(dataHomeUploaded, "project_details.housing_type.1", ""),
        },
        {
          icon: IconCalendar,
          label: `${_.get(
            dataHomeUploaded,
            "project_details.construction_year.0",
            ""
          )} `,
          value: _.get(
            dataHomeUploaded,
            "project_details.construction_year.1",
            ""
          ),
        },
        {
          icon: IconStair,
          label: `${_.get(dataHomeUploaded, "project_details.floor.0", "")} : `,
          value: _.get(dataHomeUploaded, "project_details.floor.1", ""),
        },
      ];

      const TAB_DU_BIEN2 = [
        {
          icon: IconWall,
          label: `${_.get(
            dataHomeUploaded,
            "project_details.materials.0",
            ""
          )} : `,
          value: _.get(dataHomeUploaded, "project_details.materials.1", ""),
        },
        {
          icon: IconWind,
          label: `${_.get(
            dataHomeUploaded,
            "project_details.airflow.0",
            ""
          )} : `,
          value: _.get(dataHomeUploaded, "project_details.airflow.1", ""),
        },
        {
          icon: IconIce,
          label: `${_.get(
            dataHomeUploaded,
            "project_details.aircon.0",
            ""
          )} : `,
          value: _.get(dataHomeUploaded, "project_details.aircon.1", ""),
        },
        {
          icon: IconEnergy,
          label: `${_.get(
            dataHomeUploaded,
            "project_details.energy.0",
            ""
          )} : `,
          value: _.get(dataHomeUploaded, "project_details.energy.1", ""),
        },
      ];

      return (
        <div className="info-du-bien">
          <div className="info-du-bien__inner">
            <ul>
              {TAB_DU_BIEN1.map((item, index) => {
                const Icon = item.icon;
                return (
                  <li key={index}>
                    <Flex align="center">
                      <Icon className="mr-1" />
                      {item.label && (
                        <span className="text-medium mr-1">{item.label}</span>
                      )}
                      {item.value && (
                        <p className="text-medium-bold text-truncate">
                          {item.value}
                        </p>
                      )}
                    </Flex>
                  </li>
                );
              })}
            </ul>

            <ul>
              {TAB_DU_BIEN2.map((item, index) => {
                const Icon = item.icon;
                return (
                  <li key={index}>
                    <Flex align="start">
                      <div className="flex items-center flex-shrink-0">
                        <Icon className="mr-1 flex-shrink-0" />
                        {item.label && (
                          <p className="text-medium mr-1">{item.label} </p>
                        )}
                      </div>
                      {item.value && (
                        <p
                          className="text-medium-bold text-truncate max-w-[90%] relative top-[1px]"
                          style={{ lineHeight: "33px" }}
                        >
                          {item.value}
                        </p>
                      )}
                    </Flex>
                  </li>
                );
              })}
            </ul>
          </div>
          <div className="mt-3">
            <div className="flex items-center gap-1">
              <IconTemperature />
              <span className="text-medium">
                {_.get(
                  dataHomeUploaded,
                  "project_details.heating_system.0",
                  ""
                )}{" "}
                :{" "}
                <b>
                  {_.get(
                    dataHomeUploaded,
                    "project_details.heating_system.1",
                    ""
                  )}
                </b>
              </span>
            </div>
            <p className="text-medium mt-1">
              {_.get(
                dataHomeUploaded,
                "project_details.heating_system_details.1",
                ""
              )}
            </p>
          </div>

          <div className="mt-2">
            <div className="flex items-center gap-1">
              <IconWater />
              <span className="text-medium">
                {_.get(dataHomeUploaded, "project_details.hot_water.0", "")} :{" "}
                <b>
                  {_.get(dataHomeUploaded, "project_details.hot_water.1", "")}
                </b>
              </span>
            </div>
            <p className="text-medium mt-1">
              {_.get(
                dataHomeUploaded,
                "project_details.hot_water_details.1",
                ""
              )}
            </p>
          </div>
        </div>
      );
    };

    const renderTabDPE = () => {
      const doneOn = _.get(dataHomeUploaded, "dpe_details.done_on.1", dayjs());
      const validUntil = _.get(
        dataHomeUploaded,
        "dpe_details.valid_until.1",
        dayjs()
      );

      return (
        <div className="manage-infos">
          <div className="flex items-center gap-1">
            <IconHash />
            <span className="text-[16px]">
              ADEME : <b>{_.get(dataHomeUploaded, "ademe", "")}</b>
            </span>
          </div>

          <div className="flex items-center gap-1">
            <IconOldCalendar />
            <span className="text-[16px]">
              {_.get(dataHomeUploaded, "dpe_details.done_on.0", "")} :{" "}
              <b>{dayjs.unix(doneOn).format("DD/MM/YYYY")}</b>
            </span>
          </div>

          <div className="flex items-center gap-1">
            <IconOldCalendar />
            <span className="text-[16px]">
              {_.get(dataHomeUploaded, "dpe_details.valid_until.0", "")} :{" "}
              <b>{dayjs.unix(validUntil).format("DD/MM/YYYY")}</b>
            </span>
          </div>

          <div className="flex items-center gap-1">
            <IconAttachment />
            <span className="text-[16px]">
              {_.get(dataHomeUploaded, "dpe_details.file_url.0", "")} :{" "}
              <b>{_.get(dataHomeUploaded, "dpe_details.file_url.1", "")}</b>
            </span>
          </div>

          <div className="flex items-center gap-1">
            <IconCog className="w-8 h-8" />
            <span className="text-[16px]">
              {_.get(dataHomeUploaded, "dpe_details.diagnotic_software.0", "")}{" "}
              :{" "}
              <b>
                {_.get(
                  dataHomeUploaded,
                  "dpe_details.diagnotic_software.1",
                  ""
                )}
              </b>
            </span>
          </div>

          <div className="flex items-center gap-1">
            <IconCog className="w-8 h-8" />
            <span className="text-[16px]">
              {_.get(
                dataHomeUploaded,
                "dpe_details.diagnotic_calculation_version.0",
                ""
              )}{" "}
              :{" "}
              <b>
                {_.get(
                  dataHomeUploaded,
                  "dpe_details.diagnotic_calculation_version.1",
                  ""
                )}
              </b>
            </span>
          </div>

          {_.get(dataHomeUploaded, "dpe_details.prev_ref.1") && (
            <div className="flex items-center gap-1">
              <IconHash />
              <span className="text-[16px]">
                {_.get(dataHomeUploaded, "dpe_details.prev_ref.0", "")} :{" "}
                <Link
                  to={getDashboardURL(
                    _.get(dataHomeUploaded, "dpe_details.prev_ref.1", "")
                  )}
                >
                  <b style={{ color: "rgb(22, 119, 255)" }}>
                    {_.get(dataHomeUploaded, "dpe_details.prev_ref.1", "")}
                  </b>
                </Link>
              </span>
            </div>
          )}

          {_.get(dataHomeUploaded, "dpe_details.prev_reason.1") && (
            <div className="flex items-center gap-1">
              <IconHelp className="w-8 h-8" />
              <span className="text-[16px]">
                {_.get(dataHomeUploaded, "dpe_details.prev_reason.0", "")} :{" "}
                <b>
                  {_.get(dataHomeUploaded, "dpe_details.prev_reason.1", "")}
                </b>
              </span>
            </div>
          )}
        </div>
      );
    };

    return [
      { key: "1", label: "Infos du bien", children: renderTabDuBien() },
      { key: "2", label: "Infos du DPE", children: renderTabDPE() },
    ];
  }, [dataHomeUploaded]);

  const renderMap = () => {
    return (
      <div className="map-wrapper">
        <MapContainer
          center={[
            _.get(dataHomeUploaded, "geo_data.lat"),
            _.get(dataHomeUploaded, "geo_data.lng"),
          ]}
          zoom={18}
          scrollWheelZoom={false}
          ref={mapRef}
        >
          <TileLayer
            url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png"
            attribution='&copy; <a href="https://carto.com/attributions">CartoDB</a>'
          />

          {!_.isEmpty(searchDpesMap) &&
            searchDpesMap.map((item, index) => {
              const customIcon = L.divIcon({
                className: "custom-div-icon",
                html: ReactDOMServer.renderToString(
                  <div
                    data-id={item.ademe_id}
                    className={classNames(
                      "label-container min-w-[32px] min-h-[25px] rounded-3xl flex items-center justify-center",
                      {
                        "label-container--selected":
                          item.ademe_id === selectedLabelId,
                      }
                    )}
                    style={{
                      background: MAP_COLOR_DPE[item.label],
                    }}
                  >
                    <span className="label-text font-medium">
                      {item.label}
                      {item.label_range?.length > 1 ? "+" : ""}
                    </span>
                  </div>
                ),
                iconSize: [30, 30],
              });

              return (
                <Marker
                  position={item}
                  key={index}
                  icon={customIcon}
                  zIndexOffset={item.label_z}
                  eventHandlers={{
                    click: () => handleClickLabel(item),
                  }}
                />
              );
            })}

          <Marker
            position={[
              _.get(dataHomeUploaded, "geo_data.lat"),
              _.get(dataHomeUploaded, "geo_data.lng"),
            ]}
            zIndexOffset={99999}
          >
            <Popup>
              A pretty CSS3 popup. <br /> Easily customizable.
            </Popup>
          </Marker>
        </MapContainer>
      </div>
    );
  };

  const renderGoogleMap = () => {
    return (
      <div className="energy-home__maps">
        <div className="map-wrapper">
          {isLoaded && centerPoint && (
            <GoogleMap
              mapContainerStyle={{
                width: "100%",
                height: "100%",
              }}
              center={{ lat: -33.872, lng: 151.252 }}
              zoom={5}
              onLoad={onLoad}
              onUnmount={onUnmount}
            >
              {/* Child components, such as markers, info windows, etc. */}
            </GoogleMap>
          )}
        </div>
      </div>
    );
  };

  const settings: Settings = {
    dots: false,
    infinite: false,
    speed: 400,
    slidesToShow:
      resultAutocomplete.length <= 3 ? resultAutocomplete.length : 3.3,
    slidesToScroll:
      resultAutocomplete.length <= 3 ? resultAutocomplete.length : 3,
    arrows: resultAutocomplete.length <= 3 ? false : true,
    nextArrow: <RightOutlined />,
    prevArrow: <LeftOutlined />,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow:
            resultAutocomplete.length <= 2 ? resultAutocomplete.length : 2,
          slidesToScroll:
            resultAutocomplete.length <= 2 ? resultAutocomplete.length : 2,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const tabMapItems: TabsProps["items"] = [
    { key: "1", label: "Plan", children: renderMap() },
    // { key: "2", label: "Satellite", children: renderMap() },
    { key: "2", label: "Cadastre", children: renderGoogleMap() },
  ];

  const renderResultUploaded = () => {
    if (
      isPendingUploaded ||
      isFetchingFiabilite ||
      isFetchingSuggestion ||
      isPendingSVGFiabilite
    ) {
      return (
        <div className="loading-center">
          <Spin indicator={<LoadingOutlined />} size="large" />
        </div>
      );
    }

    if (isError && _.isEmpty(dataHomeUploaded)) {
      return (
        <Card className="mb-3 min-h-[400px] flex items-center justify-center">
          <Empty />
        </Card>
      );
    }

    if (isSuccess && !_.isEmpty(dataHomeUploaded)) {
      const userToken = getUserToken();
      const userInfoAWS = cognitoUserPool.getCurrentUser();
      const isLogin = !_.isEmpty(userInfoAWS) || !!userToken;
      return (
        <div className={classNames("energy-home__result")}>
          <Card className="energy-home__project-card mb-3">
            <div className="energy-home__project">
              <h3 className="flex items-center gap-x-2.5 font-bold text-[28px] lg:text-[40px] leading-[48px] mb-3">
                <IconMondpe className="w-7 h-7 flex-shrink-0" />
                {_.get(dataHomeUploaded, "project_name")}
              </h3>

              <Flex align="center" className="mb-2">
                <IconLocation />
                <span className="text-medium-bold ml-1">
                  {_.get(dataHomeUploaded, "project_details.address.1", "")}
                </span>
              </Flex>

              <div className="energy-home__project-info">
                <Tabs
                  centered
                  activeKey={tab}
                  items={tabItems}
                  onChange={setTab}
                />

                <ResultatTab />
              </div>
            </div>
          </Card>

          <div className="energy-home__result-auth">
            <Card className="mb-3">
              <h3 className="flex items-center gap-x-2.5 font-bold text-[28px] lg:text-[40px] leading-[48px] mb-3">
                <IconReliability className="w-7 h-7 flex-shrink-0" />
                Fiabilité de mon DPE
              </h3>

              {!_.isEmpty(dataFiabilite) ? (
                <div className="energy-home__project-info__indice">
                  <div className="flex flex-col items-center gap-4">
                    <Flex align="center" className="mb-1">
                      <p className="text-large">Indice de fiabilité</p>
                      <div className="flex items-center">
                        <span
                          className="dot"
                          ref={dotFiabiliteProgressRef}
                        ></span>
                        <span className="fiabilite-number">
                          {dataFiabilite?.reliability?.level}
                        </span>
                      </div>
                    </Flex>

                    <div className="flex flex-col items-center">
                      {svgFiabilite && (
                        <div>
                          <img
                            src={`data:image/svg+xml;utf8,${encodeURIComponent(
                              svgFiabilite || ""
                            )}`}
                            alt="result"
                            className="mx-auto"
                          />
                        </div>
                      )}
                      <div>
                        <div className="flex items-center justify-center gap-3 mt-2">
                          <div className="flex items-center gap-2">
                            <span className="dot-large dot-coherence"></span>
                            <span className="text-regular-bold">
                              {_.get(
                                dataFiabilite,
                                "reliability.consistency.label",
                                ""
                              )}
                            </span>
                            <InfoCircleOutlined
                              onClick={() =>
                                handleSetDataModalFiabilite(
                                  _.get(
                                    dataFiabilite,
                                    "reliability.consistency",
                                    null
                                  ),
                                  "consistency"
                                )
                              }
                            />
                          </div>

                          <div className="flex items-center gap-2">
                            <span className="dot-large dot-qualite"></span>
                            <span className="text-regular-bold">
                              {_.get(
                                dataFiabilite,
                                "reliability.quality.label",
                                ""
                              )}
                            </span>
                            <InfoCircleOutlined
                              onClick={() =>
                                handleSetDataModalFiabilite(
                                  _.get(
                                    dataFiabilite,
                                    "reliability.quality",
                                    null
                                  ),
                                  "quality"
                                )
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="flex items-center justify-evenly flex-wrap gap-4 relative top-1">
                    <div className="flex flex-wrap gap-3">
                      <div className="anomalies">
                        <div className="flex items-center gap-2 mb-2">
                          <span className="dot-large dot-issue">
                            {_.get(
                              dataFiabilite,
                              "reliability.issues.number",
                              ""
                            )}
                          </span>
                          <span className="text-regular-bold">
                            {_.get(
                              dataFiabilite,
                              "reliability.issues.label",
                              ""
                            )}
                          </span>
                          <InfoCircleOutlined
                            onClick={() =>
                              handleSetDataModalFiabilite(
                                _.get(
                                  dataFiabilite,
                                  "reliability.issues",
                                  null
                                ),
                                "anomaly"
                              )
                            }
                          />
                        </div>
                        {_.get(
                          dataFiabilite,
                          "reliability.issues.details",
                          []
                        ).map((item: any, index: number) => (
                          <p key={index} className="mb-1 text-[16px]">
                            {item.label} : {item.details}
                          </p>
                        ))}
                      </div>

                      <div>
                        <div className="flex items-center gap-2 mb-2">
                          <span className="text-regular-bold">
                            {_.get(
                              dataFiabilite,
                              "reliability.areas.label",
                              ""
                            )}
                          </span>
                          <InfoCircleOutlined
                            onClick={() =>
                              handleSetDataModalFiabilite(
                                _.get(dataFiabilite, "reliability.areas", null),
                                "areas"
                              )
                            }
                          />
                        </div>

                        {_.get(
                          dataFiabilite,
                          "reliability.areas.details",
                          []
                        ).map((item: any, index: number) => {
                          const Icon = MAP_ICON[item.type];
                          return (
                            <div
                              className="flex items-center gap-1.5 mb-2"
                              key={index}
                            >
                              <Icon />
                              <p className="text-[16px]">{item.label}</p>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div>
                  <Flex align="flex-start" justify="space-between">
                    <p className="text-large mb-1">Indice de fiabilité</p>
                    <div className="flex items-center">
                      <span
                        className="dot"
                        ref={dotFiabiliteProgressRef}
                      ></span>
                      <span className="fiabilite-number">
                        {dataFiabilite?.reliability?.level}
                      </span>
                    </div>
                  </Flex>
                  <Empty imageStyle={{ height: 250 }} />
                </div>
              )}
            </Card>

            <Card className="mb-3">
              <h3 className="flex items-center gap-x-2.5 font-bold text-[28px] lg:text-[40px] leading-[48px] mb-3">
                <IconFinance className="w-7 h-7 flex-shrink-0" />
                Mon DPE par rapport aux autres
              </h3>

              <Row gutter={[24, 24]} style={{ marginLeft: 0, marginRight: 0 }}>
                <Col span={24} lg={9} style={{ paddingLeft: 0 }}>
                  <DistributionChart
                    ref={distributionRef}
                    dataHomeUploaded={dataHomeUploaded}
                    isPendingUploaded={isPendingUploaded}
                  />

                  <GraphePrice dataGraphePrice={dataGraphePrice} />
                  {/* <GraphePrice dataGraphePrice={{min: 1500, max: 2500, peek: 1600, expected: 1800}} /> */}
                </Col>
                <Col span={24} lg={15} style={{ paddingRight: 0 }}>
                  <div className="energy-home__maps">
                    <div className="flex justify-between">
                      <Flex align="center" className="mt-2 mb-4">
                        <IconLocation />
                        <span className="text-regular-bold sm:text-medium-bold ml-1">
                          {_.get(
                            dataHomeUploaded,
                            "project_details.address.1",
                            ""
                          )}
                        </span>
                      </Flex>

                      <Button
                        type="link"
                        className="flex items-center justify-center p-0 w-9 h-9"
                        onClick={() => setIsShowModalMapInfo(true)}
                      >
                        <InfoCircleOutlined
                          style={{ color: "#000", fontSize: 22 }}
                        />
                      </Button>
                    </div>

                    <div className="energy-home__map-info">
                      <Tabs
                        centered
                        activeKey={tabMap}
                        items={tabMapItems}
                        onChange={setTabMap}
                      />
                    </div>

                    {!_.isEmpty(resultAutocomplete) &&
                      resultAutocompleteTitle && (
                        <div className="mt-4">
                          <p className="font-bold text-[16px]">
                            {resultAutocompleteTitle}
                          </p>
                          <div className="py-4 px-5">
                            <Slider
                              {...settings}
                              ref={(slider) => {
                                sliderRef.current = slider;
                              }}
                            >
                              {renderGPSItem()}
                            </Slider>
                          </div>
                        </div>
                      )}
                  </div>
                </Col>
              </Row>
            </Card>

            <SuggestionRenovation dataSuggestion={dataSuggestion} />

            {!isLogin && (
              <div className="overlay">
                <div className="overlay__content">
                  <div className="offre-item">
                    <p className="title">Offre 1</p>
                    <p className="description">
                      Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                      Repellat ea eum rerum dolores a, impedit ab explicabo ex
                      quae tempora excepturi ad omnis blanditiis nihil esse sunt
                      perspiciatis odio! Itaque? <br /> <br />
                      Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                      Repellat ea eum rerum dolores a, impedit ab explicabo ex
                      quae tempora excepturi ad omnis blanditiis nihil esse sunt
                      perspiciatis odio! Itaque?
                    </p>
                    <Button
                      type="primary"
                      className="w-full h-[40px]"
                      onClick={() => navigate(PAGES.login)}
                    >
                      Choisir
                    </Button>
                  </div>

                  <div className="offre-item">
                    <p className="title">Offre 2</p>
                    <p className="description">
                      Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                      Repellat ea eum rerum dolores a, impedit ab explicabo ex
                      quae tempora excepturi ad omnis blanditiis nihil esse sunt
                      perspiciatis odio! Itaque? <br /> <br /> Lorem ipsum dolor
                      sit, amet consectetur adipisicing elit. Repellat ea eum
                      rerum dolores a, impedit ab explicabo ex quae tempora
                      excepturi ad omnis blanditiis nihil esse sunt perspiciatis
                      odio! Itaque?
                    </p>
                    <Button
                      type="primary"
                      className="w-full h-[40px]"
                      onClick={() => navigate(PAGES.login)}
                    >
                      Choisir
                    </Button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      );
    }

    return null;
  };

  const renderGPSItem = () => {
    if (isPendingSearchMap) {
      return [...new Array(3)].map((item, index) => (
        <Skeleton.Node active key={index} />
      ));
    }

    if (!_.isEmpty(resultAutocomplete)) {
      return resultAutocomplete.map((item, index) => (
        <div className="autocomplete-item" key={index}>
          <div className="autocomplete-item__title">
            <p
              style={{
                background: MAP_COLOR_DPE[_.get(item, "label.1")],
              }}
              className="min-w-[32px] rounded-full text-center"
            >
              {_.get(item, "label.1")}
            </p>
            <p className="truncate">{_.get(item, "ademe_id.1")}</p>
          </div>
          <div className="autocomplete-item__content">
            <ul
              style={{ listStyleType: "disc" }}
              className="flex flex-col gap-2 pl-8"
            >
              {_.get(item, "date_dpe.1") && (
                <li>
                  <p className="truncate">
                    {_.get(item, "date_dpe.0")}: {_.get(item, "date_dpe.1")}
                  </p>
                </li>
              )}

              {_.get(item, "address.1") && (
                <li>
                  <p className="truncate">{_.get(item, "address.1")}</p>
                </li>
              )}

              {_.get(item, "floor.1") && (
                <li>
                  <p className="truncate">
                    {_.get(item, "floor.0")}: {_.get(item, "floor.1")}
                  </p>
                </li>
              )}

              {_.get(item, "housing_surface.1") && (
                <li>
                  <p className="truncate">
                    {_.get(item, "housing_surface.0")}:{" "}
                    {_.get(item, "housing_surface.1")}
                  </p>
                </li>
              )}

              {_.get(item, "housing_add_compl.1") && (
                <li>
                  <p className="truncate">
                    {_.get(item, "housing_add_compl.1")}
                  </p>
                </li>
              )}
            </ul>
          </div>
        </div>
      ));
    }
    return null;
  };

  return (
    <div className="energy-home energy-home--result">
      {renderResultUploaded()}
      {contextHolder}
      <Modal
        open={isShowModalMapInfo}
        centered
        onCancel={() => setIsShowModalMapInfo(false)}
        onOk={() => setIsShowModalMapInfo(false)}
        cancelButtonProps={{ className: "hidden" }}
      >
        <div className="p-4">
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Veritatis
            unde vitae sit placeat mollitia molestias animi quasi amet eaque
            quo, culpa delectus quae quisquam iusto obcaecati dicta voluptate.
            Deleniti, aspernatur?
          </p>
        </div>
      </Modal>

      <Modal
        open={isShowModalFiabilite}
        centered
        title={_.get(dataModalFiabilite, "details.title", "")}
        onCancel={() => setIsShowModalFiabilite(false)}
        onOk={handleOkFiabilite}
        confirmLoading={isPendingChatInit}
        cancelButtonProps={{ className: "hidden" }}
      >
        <div className="p-4">
          <ul style={{ listStyleType: "disc" }} className="flex flex-col gap-2">
            {_.get(dataModalFiabilite, "details.body", []).map(
              (item, index) => (
                <li key={index}>{item}</li>
              )
            )}
          </ul>
        </div>
      </Modal>
    </div>
  );
}

export default Dashboard;
